<template>
	<modalLayout title="Add Work Shift">
		<form @submit.prevent="addShift(modal_prop, $toastr)">
			<div class="row">
				<div class="col-12">
					<div class="w-full">
						<div class="form-group w-full">
							<label class="form-label"> Start time </label>
							<div class='w-full'>
								<vue-timepicker
									hide-clear-button
									v-model="form.start_time"
									input-class="form-control"
									style="width: 100%"
									format="h:mm A"
									></vue-timepicker>
							</div>
						</div>
					</div>
					<div class="w-full">
						<div class="form-group w-full">
							<label class="form-label"> End time</label>
							<div class='w-full'>
								<vue-timepicker
									hide-clear-button
									v-model="form.end_time"
									input-class="form-control"
									style="width: 100%"
									format="h:mm A"
									></vue-timepicker>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="w-100 mt-[60px]">
				<button
					:disabled="processing || !formValid"
					type="submit"
					class="btn btn-primary w-100"
					>
					{{ processing ? 'Processing...' : 'Add work shift' }}
				</button>
			</div>
		</form>
	</modalLayout>
</template>

<script setup>
import {defineProps} from 'vue'
import modalLayout from '@/layouts/core/modal.vue'
import VueTimepicker from 'vue2-timepicker'
import {onMounted, computed} from 'vue'
import {useShifts} from '@/composables/backend/companies/workshift'

const { processing, addShift, form, resetForm } = useShifts()
const props = defineProps(['modal_prop'])


const formValid = computed(() => {
	return !!(form.value.start_time && form.value.end_time)
})

resetForm()

onMounted(() => {
	form.value.start_time = '8:00 AM'
	form.value.end_time = '5:00 PM'
})

</script>

<style lang="scss">
.vue__time-picker input.display-time {
  display: block;
  width: 100%;
  height: calc(1.5em + 1rem + 2px) !important;
  padding: 0.5rem 0.75rem !important;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5 !important;
  color: #12263f;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #d2ddec;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>